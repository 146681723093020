.framer-div {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  border: none;
}

.framer {
  width: 100%;
  height: 100%;
  border: 0px;
}
