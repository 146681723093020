.mainTextTitle {
  -webkit-text-stroke: 1px #aa5fcc;
}

.mainTextDesc {
  -webkit-text-stroke: 1px #aa5fcc;
}

.startButton {
  background-image: url("../../assets/image/button.svg");
  background-size: cover;
  -webkit-text-stroke: 1px #aa5fcc;
}

.splashText {
  background: linear-gradient(
    to right,
    #7953cd 25%,
    #40dbe4 50%,
    #0190cd 75%,
    #aa5fcc 100%
  );
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: move-bg 2s infinite ease-in;
}

.gameOverTitle {
  animation: 1.5s infinite forwards gameOverTitle;
}

@keyframes move-bg {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes gameOverTitle {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
