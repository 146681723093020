@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "pixel_digivolve";
  src: url("./assets/fonts/pixel_digivolve_font/Pixel\ Digivolve.otf")
    format("opentype");
}

:root {
  --glass-stroke: rgba(255, 255, 255, 0.08);
  --btn-primary-color: #364afe;
  --glass-fill: rgba(215, 231, 237, 1), rgba(204, 235, 235, 0);
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: #131619;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #364152;
  opacity: 0.1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-between {
    @apply flex items-center justify-between;
  }

  .flex-start {
    @apply flex items-center justify-start;
  }

  .btn {
    @apply border-t-[color:var(--glass-stroke,rgba(255,255,255,0.08))] border-t border-solid;
    background: linear-gradient(274deg, #6049e6 0.42%, #3416ac 94.17%);
  }

  .btn-secondary {
    @apply border-t-[color:var(--glass-stroke,rgba(255,255,255,0.08))] border-t border-solid;
    background: linear-gradient(
      118deg,
      rgba(135, 117, 248, 0.6) -47.79%,
      rgba(153, 145, 244, 0.17) 100%
    );
  }

  .btn-tertiary {
    @apply border border-solid border-[var(--btn-primary-color)];
    background: linear-gradient(268deg, #7b5bff -0.81%, #4b26e1 98.2%);
  }

  .btn-quaternary {
    border-top: 1px solid var(--glass-stroke, rgba(255, 255, 255, 0.08));
    background: linear-gradient(
      118deg,
      rgba(215, 237, 237, 0.16) -47.79%,
      rgba(204, 235, 235, 0) 100%
    );
  }

  .primary-gradient {
    background: linear-gradient(129deg, #ff7000 0%, #e2995f 100%);
  }

  .dark-gradient {
    background: linear-gradient(
      232deg,
      rgba(23, 28, 35, 0.41) 0%,
      rgba(19, 22, 28, 0.7) 100%
    );
  }

  .light-gradient {
    background: linear-gradient(
      132deg,
      rgba(247, 249, 255, 0.5) 0%,
      rgba(229, 237, 255, 0.25) 100%
    );
  }

  .no-focus {
    @apply focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 !important;
  }

  .prompt-btn {
    background: linear-gradient(232deg, #967ef8 0%, #4d28e2 191.59%);
  }

  .loader-gradient {
    background: var(--glass-modal, rgba(26, 29, 33, 0.96));
  }

  .loader-icon-gradient {
    background: linear-gradient(180deg, #313131 0%, rgba(49, 49, 49, 0) 100%);
  }

  .view-chart-gradient {
    background: linear-gradient(
      78deg,
      #191a1b 0.46%,
      rgba(29, 29, 33, 0.88) 99.67%
    );
  }

  .submit-gradient {
    background: linear-gradient(92deg, #3a4cee 0.76%, #3a4cee 98.73%);
  }

  .gradient-dark {
    background: linear-gradient(268deg, #292632 -0.81%, #1f1d25 98.2%);
  }

  .overlay {
    position: absolute;
    bottom: 0;
    border-radius: 16px 16px 0px 0px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    background: linear-gradient(
      78deg,
      #191a1b 0.46%,
      rgba(29, 29, 33, 0.88) 99.67%
    );
  }

  .launch-screen-gradient {
    background: linear-gradient(180deg, #141517 0%, #232528 100%);
  }

  .primary-text-gradient {
    background: linear-gradient(129deg, #ff7000 0%, #e2995f 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 2px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@keyframes shakeicon {
  0% {
    transform: rotate(-10deg);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.animated-element {
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.42, 0, 0.55, 0.99);
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: shakeicon;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-text {
  animation: blink 3s infinite;
  width: 7%;
  font-size: min(1.36vw, 2rem);
}

.loader-circle-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  transform: translate(-50%, -50%);
  animation: jittery-rotate 3s linear infinite;
}

@keyframes jittery-rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(30deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(10deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(60deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.form-button {
  padding: 0.75rem 1.25rem;
  border: none;
  outline: none;
  color: #ffffff;
  background: linear-gradient(45deg, #fff);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 1px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.form-button:before {
  content: "";
  background: linear-gradient(45deg, #fff, #000, #fff, #000, #fff);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-form-button 40s linear infinite;
  transition: opacity 5s ease-in-out;
  border-radius: 10px;
}

.form-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #050a30ea;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing-form-button {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.uploaded-image {
  margin-left: 4rem;
}

.rating-box {
  background-color: #141414;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 5s linear infinite;
}
